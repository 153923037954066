<template>
    <form class="form" @submit.prevent="addRooms">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">From Room</label>
                <input type="number" placeholder="Example 5" class="form__input" v-model.number="rooms.startRoom">
    
                <div class="form__input-error" v-if="errors.fields.startRoom">
                    {{ errors.fields.startRoom[0] }}
                </div>
            </div>
    
            <div class="form__input-form">
                <label class="form__input-label form--required">To Room</label>
                <input type="number" placeholder="Example 10" class="form__input" v-model.number="rooms.endRoom">
    
                <div class="form__input-error" v-if="errors.fields.endRoom">
                    {{ errors.fields.endRoom[0] }}
                </div>
            </div>
    
            <div class="form__input-form">
                <label class="form__input-label">Rooms Letter</label>
                <input type="text" placeholder="Axample A, B, C..." class="form__input" v-model="rooms.prefixRoom">
    
                <div class="form__input-error" v-if="errors.fields.prefixRoom">
                    {{ errors.fields.prefixRoom[0] }}
                </div>
            </div>
    
            <div class="form__input-form">
                <label class="form__input-label form--required">Floor</label>
                <select class="form__input" v-model="rooms.floorId">
                    <option value="">Select Floor Location</option>
                    <option :value="floor.id" v-for="floor in floors" :key="floor.id">
                        {{ floor.name }} {{ floor.prefix }}
                    </option>
                </select>
    
                <div class="form__input-error" v-if="errors.fields.floorId">
                    {{ errors.fields.floorId[0] }}
                </div>
            </div>
    
            <div class="form__input-form">
                <label class="form__input-label form--required">Average Time To Service</label>
                <input type="number" placeholder="Duration of service in minutes" class="form__input"
                    v-model.number="rooms.averageTime">
    
                <div class="form__input-error" v-if="errors.fields.averageTime">
                    {{ errors.fields.averageTime[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/add-circle.svg" alt="">
                Add Rooms
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { roomsServices } from '../Services/RoomsServices'
import Notifications from '../../../common/Notifications.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'

const floors = ref([])
const notification = ref(null)
const { addMultipleRoomsService, getFloorsWithoutPagination, errors } = roomsServices()
const buttonLoader = ref(false)
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const rooms = reactive({
    startRoom: '',
    endRoom: '',
    prefixRoom: '',
    averageTime: '',
    floorId: ''
})

onMounted(() => { getFloors() })

async function addRooms() {
    // * Show button loader
    buttonLoader.value = true

    const response = await addMultipleRoomsService(rooms)

    if (response.hasErrors) {
        buttonLoader.value = false
        return notification.value.sendNotification('error', 'Error', response.message)
    }

    router.push('/hotel_setup/rooms')

    return sweetSuccess(response.data.message)
}

async function getFloors() {
    const response = await getFloorsWithoutPagination()
    floors.value = response.data
}
</script>