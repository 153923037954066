<template>
    <div class="radio-inputs radio-inputs--row">
        <div class="radio-inputs__radio">
            <input type="radio" id="single" checked name="floor-option" @change="selectedForm(SingleRoomFormAddComponent)">
            <label for="single">Add Single Room</label>
        </div>

        <div class="radio-inputs__radio">
            <input type="radio" id="multiple" name="floor-option" @change="selectedForm(MultipleRoomsFormAddComponent)">
            <label for="multiple">Add Multiple Rooms</label>
        </div>
    </div>
</template>

<script setup>
import SingleRoomFormAddComponent from './SingleRoomFormAddComponent.vue'
import MultipleRoomsFormAddComponent from './MultipleRoomsFormAddComponent.vue'
import { onMounted } from 'vue'

const emit = defineEmits(['selectedForm'])

onMounted(() => {
    emit('selectedForm', SingleRoomFormAddComponent)
})

function selectedForm(formComponent) {
    emit('selectedForm', formComponent)
}
</script>