<template>
    <div class="modal-form">
        <div class="modal-form__content-modal">
            <router-link class="modal-form__title" to="/hotel_setup/rooms">
                <img src="@/assets/icons/vuesax/linear/arrow-left.svg" alt="">
                Add Rooms
            </router-link>

            <OptionsComponent @selectedForm="setFormView" />

            <component :is="roomForm" />
        </div>
    </div>
</template>

<script setup>
import OptionsComponent from '../Components/OptionsComponent.vue'
import { shallowRef } from 'vue'

let roomForm = shallowRef(null)

function setFormView(formComponent) {
    roomForm.value = formComponent
}
</script>